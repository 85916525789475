import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import { MHP_PROMPT, MHP_CHATLOG_DELIMETER, MHP_ARTICLES_DELIMETER } from '../data/llm/openai';
import ModalSlide from './ModalSlide';
import { useInput } from '../hooks/useInput';
import { request } from '../data/llm/openai';
import { streamReadMessage } from '../data/makeRequest';
import './MHPArticleSummariser.scss';
import MHPArticleGetterForm, {
  ARTICLE_STATUSES,
  URL_NO_PROTOCOL_REGEX_GLOBAL,
  isValidUrl
} from './MHPArticleGetterForm';
import {
  FaAngleUp as ArticleArrow,
  FaRegCheckCircle as ArticleOk,
  FaExclamation as ArticleEmpty,
  FaExclamation as ArticleError,
  FaPencilAlt as ArticleEditing,
  FaRegTrashAlt as ArticleRemoving,
  FaEye as ArticleToCheck
} from 'react-icons/fa';

import { Readability } from '@mozilla/readability';

function getArticleObject({
  shown = false,
  expanded = false,
  articleLink = '',
  articleText = '',
  toLoad = false,
  loading = false,
  status = ARTICLE_STATUSES.START
}) {
  const article = {
    shown,
    expanded,
    articleLink,
    articleText,
    toLoad,
    loading,
    status
  };
  return { ...article, updated: article };
}

function getDisplayUrl(url: string) {
  try {
    return new URL(url).host;
  } catch (e) {
    return '-';
  }
}

type ArticleFieldsType = {
  articleLink: string;
  articleText: string;
};

type ArticleType = ArticleFieldsType & {
  shown?: boolean;
  expanded: boolean;
  status: number;
  toLoad: boolean;
  updated: ArticleFieldsType;
};

type ParamsType = {
  inputPrompt: string;
  onClose: () => void;
  onDone: (result: boolean) => void;
};

function MHPArticleSummariser({ inputPrompt, onClose, onDone }: ParamsType) {
  const [modalOpened, setModalOpened] = useState(true);
  const textareaUrlsRows = 10;
  const [urlsTextAreaValue, setUrlsTextAreaValue] = useState('');
  const [articles, setArticles] = useState<Array<ArticleType>>([]);
  const [displayedArticles, setDisplayedArticles] = useState<Array<ArticleType>>([]);
  const [disableSummariseButton, setDisableSummariseButton] = useState(true);
  const { runInputPrompt } = useInput();
  const urlsTextAreaRef = useRef(null);
  const formRef = useRef(null);

  const onModalClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const resetArticle = useCallback((articleList: Array<ArticleType>, article: ArticleType, articleIndex: number) => {
    const articles = [...articleList];
    articles.splice(articleIndex, 1, article);
    setArticles(() => articles);
  }, []);

  const getText = useCallback(
    (link: string) => {
      const articleIndex = articles.findIndex(({ articleLink }) => link === articleLink);
      let article = articles[articleIndex];
      const [isValid, url] = isValidUrl(link);
      if (isValid) {
        article.status = ARTICLE_STATUSES.LOADING;
        resetArticle(articles, article, articleIndex);
        let isHtml = false;
        const onGetArticleMessage = (message: string, { whole_page }: { whole_page: boolean; message: string }) => {
          if (whole_page) {
            isHtml = whole_page;
          }
        };

        request('/mhp-get-text', { url }).then((response) => {
          streamReadMessage(response, onGetArticleMessage)
            .then((text) => {
              if (!isHtml) {
                article.articleText = text;
                article.status = text ? ARTICLE_STATUSES.OK : ARTICLE_STATUSES.EMPTY_VALUE;
                article.updated.articleText = text;
              } else {
                const domParser = new DOMParser();
                const parsed = domParser.parseFromString(text, 'text/html');
                const reader = new Readability(parsed);
                const textContent = reader.parse()?.textContent.replace(/\n+/g, '\n') || '';

                article.articleText = textContent;
                article.status = ARTICLE_STATUSES.LOADED_BASE;
                article.updated.articleText = textContent;
              }
              resetArticle(articles, article, articleIndex);
            })
            .catch(() => {
              article.status = ARTICLE_STATUSES.ERROR;
              resetArticle(articles, article, articleIndex);
            });
        });
      } else {
        article.status = ARTICLE_STATUSES.ERROR;
        resetArticle(articles, article, articleIndex);
      }
    },
    [articles, resetArticle]
  );

  const onGetTextRequest = useCallback(
    (link: string) => {
      getText(link);
    },
    [getText]
  );

  const setArticlesFromUrls = useCallback((valueStr: string) => {
    const r = new RegExp(URL_NO_PROTOCOL_REGEX_GLOBAL);
    let matchList: Array<string> = [];

    const values = valueStr.split(/[ \n,;]/);
    for (const value of values) {
      for (const match of value.matchAll(r)) {
        const [isValid, link] = isValidUrl(match[0]);
        if (isValid) {
          matchList.push(link);
        }
      }
    }

    setArticles((articles) => [
      ...articles.filter(({ shown }) => shown),
      ...matchList
        .reduce((res: Array<string>, url: string) => {
          if (res.indexOf(url) === -1) {
            if (articles.findIndex(({ articleLink }) => articleLink === url) === -1) {
              return [...res, url];
            }
          }
          return res;
        }, [])
        .map((articleLink) => getArticleObject({ articleLink, toLoad: true }))
    ]);
  }, []);

  const onUrlsTextAreaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const valueStr = e.target.value || '';
    setUrlsTextAreaValue(valueStr);
  }, []);

  const onGetArticlesSubmit = useCallback(
    (e: SubmitEvent) => {
      e.preventDefault();

      setArticles(() =>
        articles.map(({ shown, status, ...article }) => ({
          ...article,
          status: shown === false ? ARTICLE_STATUSES.START : status,
          shown: true
        }))
      );

      if (articles.length) {
        setUrlsTextAreaValue(() => '');
      }
    },
    [articles]
  ) as unknown as React.FormEventHandler<HTMLFormElement>;

  const switchEditStatus = useCallback(
    ({ status, ...articleValues }: ArticleType) => ({
      ...articleValues,
      status: status === ARTICLE_STATUSES.EDITING ? ARTICLE_STATUSES.OK : status
    }),
    []
  );

  const onRemoveArticle = useCallback(
    (link: string) => {
      const arts = [...articles];
      const findIndex = arts.findIndex(({ articleLink }) => link === articleLink);
      arts.splice(findIndex, 1);
      setArticles(() => arts);
    },
    [articles]
  );

  const toggleArticleExpand = useCallback(
    (link: string) => {
      const articleIndex = articles.findIndex(({ articleLink }) => articleLink === link);
      const { expanded, ...article } = articles[articleIndex];
      const resetArticle = switchEditStatus({ ...article, expanded: !expanded });
      const resetArticles = [...articles].map((article) => switchEditStatus({ ...article, expanded: false }));
      resetArticles.splice(articleIndex, 1, resetArticle);
      setArticles(() => resetArticles);
    },
    [articles, switchEditStatus]
  );

  const onArticleChanged = useCallback(
    (articleLink: string, { ...articleValues }) => {
      const { link, text } = articleValues;
      let status;
      if (link && text) {
        const [isValid] = isValidUrl(link);
        if (isValid) {
          status = ARTICLE_STATUSES.EDITING;
        } else {
          status = ARTICLE_STATUSES.ERROR;
        }
      } else {
        status = ARTICLE_STATUSES.EMPTY_VALUE;
      }
      const artIndex = articles.findIndex(({ articleLink: url }) => articleLink === url);
      if (artIndex !== -1) {
        const arts = [...articles];
        arts.splice(artIndex, 1, { ...arts[artIndex], status, updated: { articleLink: link, articleText: text } });
        setArticles(() => arts);
      }
    },
    [articles]
  );

  const onFormItemBlur = useCallback(() => {
    const resetArticles: Array<ArticleType> = [];
    for (const article of articles) {
      const {
        updated: { articleLink, articleText }
      } = article;
      const link = articleLink.trim();
      const text = articleText.trim();

      const [isValid, url] = isValidUrl(link);

      resetArticles.push({
        ...article,
        articleLink: url,
        articleText: text,
        status: !text.length || !isValid ? ARTICLE_STATUSES.ERROR : ARTICLE_STATUSES.OK,
        updated: {
          articleLink: url,
          articleText: text
        }
      });
    }
    setArticles(() => resetArticles);
  }, [articles]);

  const onSummariseButtonClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      const allOk = !articles.some(({ status }) => status !== ARTICLE_STATUSES.OK);
      if (allOk) {
        const singleInput = articles
          .map(({ articleLink, articleText }) => `${articleLink}${MHP_CHATLOG_DELIMETER}${articleText}`)
          .join(MHP_ARTICLES_DELIMETER);
        runInputPrompt({
          inputPrompt: `${MHP_PROMPT}${MHP_CHATLOG_DELIMETER}${singleInput}`,
          onSent: () => onDone(true)
        });
      }
    },
    [articles, onDone, runInputPrompt]
  ) as unknown as React.MouseEventHandler<HTMLButtonElement>;

  const onArticleConfirm = useCallback(
    (link: string) => {
      const articleIndex = articles.findIndex(({ articleLink }) => link === articleLink);
      let article = articles[articleIndex];
      article.status = ARTICLE_STATUSES.OK;
      resetArticle(articles, article, articleIndex);
    },
    [articles, resetArticle]
  );

  useEffect(() => {
    const arts = [...articles];
    setDisplayedArticles(() => arts.filter(({ shown }) => shown));
  }, [articles]);

  useEffect(() => {
    setTimeout(() => {
      setModalOpened(() => true);
    }, 100);
  }, []);

  useEffect(() => {
    const articlesToLoad = displayedArticles
      .filter(({ status }) => status === ARTICLE_STATUSES.START)
      .map(({ articleLink }) => articleLink);

    if (articlesToLoad.length) {
      for (const articleLink of articlesToLoad) {
        getText(articleLink);
      }
    }
  }, [displayedArticles, getText]);

  useEffect(() => {
    setDisableSummariseButton(() => displayedArticles.some(({ status }) => status !== ARTICLE_STATUSES.OK));
  }, [displayedArticles]);

  useEffect(() => {
    setArticlesFromUrls(urlsTextAreaValue);
  }, [urlsTextAreaValue, setArticlesFromUrls]);

  useEffect(() => {
    setUrlsTextAreaValue(inputPrompt || '');
  }, [inputPrompt]);

  return (
    <ModalSlide
      title={'Summarise articles'}
      isOpened={modalOpened}
      onModalClose={onModalClose}
      modalClassNames={{ 'mhp-article-summariser': true }}
    >
      <>
        {displayedArticles.length > 0 && (
          <>
            <div className={'mhp-get-articles-wrapper'}>
              {displayedArticles.map(
                ({ articleLink, articleText, toLoad, expanded, status, updated: { articleLink: displayUrl } }) => (
                  <div className={'mhp-get-article-row'} key={`mhp-get-article-${articleLink}`}>
                    <div className={'mhp-get-articles-list-head'}>
                      <div className={'mhp-get-articles-loader'} onClick={() => toggleArticleExpand(articleLink)}>
                        {status === ARTICLE_STATUSES.LOADING && (
                          <PulseLoader
                            color={'#fff'}
                            loading={true}
                            size={5}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        )}
                        {status === ARTICLE_STATUSES.OK && <ArticleOk />}
                        {status === ARTICLE_STATUSES.EDITING && <ArticleEditing />}
                        {status === ARTICLE_STATUSES.EMPTY_VALUE && <ArticleEmpty />}
                        {status === ARTICLE_STATUSES.ERROR && <ArticleError />}
                        {status === ARTICLE_STATUSES.LOADED_BASE && <ArticleToCheck />}
                      </div>
                      <div
                        className={'mhp-get-articles-list-head-domain'}
                        onClick={() => toggleArticleExpand(articleLink)}
                      >
                        {getDisplayUrl(displayUrl)}
                      </div>
                      <div
                        className={clsx({ expandable: true, down: expanded })}
                        onClick={() => toggleArticleExpand(articleLink)}
                      >
                        <ArticleArrow />
                      </div>
                      <div className={'mhp-remove-article'}>
                        <button type="button" onClick={() => onRemoveArticle(articleLink)}>
                          <ArticleRemoving />
                        </button>
                      </div>
                    </div>
                    <div className={clsx({ 'mhp-get-articles-list-wrapper': true, expanded })}>
                      <MHPArticleGetterForm
                        link={articleLink}
                        text={articleText}
                        toConfirm={status === ARTICLE_STATUSES.LOADED_BASE || status === ARTICLE_STATUSES.EDITING}
                        onConfirm={() => onArticleConfirm(articleLink)}
                        toLoad={toLoad}
                        onFormItemBlur={onFormItemBlur}
                        onGetTextRequest={onGetTextRequest}
                        onChanged={(values) => onArticleChanged(articleLink, { ...values })}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="mhp-summarise-articles-wrapper">
              <button type="button" disabled={disableSummariseButton} onClick={onSummariseButtonClick}>
                Summarise
              </button>
            </div>
          </>
        )}
        <div className={'mhp-add-links-form-wrapper'}>
          <form className="modalslide-form" ref={formRef} onSubmit={onGetArticlesSubmit}>
            <div className="modalslide-form-group">
              <label>Article links</label>
              <div className={'modalslide-form-row'}>
                <textarea
                  className={'mhp-urls-field'}
                  rows={textareaUrlsRows}
                  placeholder={'Paste or write article links here'}
                  onChange={onUrlsTextAreaChange}
                  value={urlsTextAreaValue}
                  ref={urlsTextAreaRef}
                />
              </div>

              <div className="modalslide-form-buttons">
                <button type="submit" className="modalslide-submit">
                  <span>Get articles</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </ModalSlide>
  );
}

export default MHPArticleSummariser;
