import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentSession } from '../data/ui';
import { getOptions } from '../data/llm/utils';
import LLMS from '../data/llm';
import { DEFAULT_LLM_KEY } from '../data/llm/types';
import { useChats } from '../hooks/useChats';

import NextDropdown from './Dropdown';
import type { ChatType } from '../data/chats-type';

const LLMOptions = getOptions(LLMS);

function getLLMLabel(llmKey: string | undefined) {
  return llmKey && LLMS.llm(llmKey as string).NAME;
}

export default function LLMSwitcher() {
  const currentSession = useSelector(selectCurrentSession);
  const { getChat, replaceChat } = useChats();
  const [chat, setChat] = useState<ChatType | null>(getChat(currentSession) || null);

  const setLLMToChat = useCallback(
    (llmKey: string) => {
      if (chat) {
        replaceChat({
          ...chat,
          llm: llmKey
        }).then(() => {});
      }
    },
    [replaceChat, chat]
  );

  useEffect(() => {
    if (currentSession !== undefined) {
      setChat(() => getChat(currentSession) || null);
    }
  }, [getChat, currentSession]);

  const llmCanBeChanged = chat?.chatLog?.length === 0 || !chat;

  return (
    <div className="llm-switcher">
      {!llmCanBeChanged ? (
        <div className="llm-label">LLM: {getLLMLabel(chat?.llm || DEFAULT_LLM_KEY)}</div>
      ) : (
        <NextDropdown
          title={getLLMLabel(chat?.llm) || 'Choose LLM (optional)'}
          options={LLMOptions.map(({ key, label }) => ({
            label,
            onClick: () => setLLMToChat(key)
          }))}
        />
      )}
    </div>
  );
}
