import React, { ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';

type ParamsType = {
  link?: string;
  text: string;
  children?: ReactNode;
  svg: ReactNode;
  onClick?: {
    (): void;
  };
};

const NavLinks = ({ svg, link, text, onClick, children }: ParamsType) => {
  const handleClick = useCallback(
    async (linkText: string, e: any) => {
      if (onClick) {
        onClick();
      } else {
        console.log('Random click', linkText);
        console.log('children', children);
      }
    },
    [children, onClick]
  );

  return (
    <Link
      to={link || ''}
      target={link && '_blank'}
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
      onClick={(e) => handleClick(text, e)}
    >
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavLinks;
