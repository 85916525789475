import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineDelete, AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import clsx from 'clsx';
import { kebabCase } from 'lodash';

import useSearch from '../hooks/useSearch';
import { selectCurrentSession, setCurrentSession, setShowMenu } from '../data/ui';
import { useChats } from '../hooks/useChats';
import type { ChatType } from '../data/chats-type';
import { useRequest } from '../hooks/useRequest';

type ParamsType = {
  chatId: string | number | undefined;
  chatPrompt: string;
  chatIndex: number;
  onChatRemoving: { (chatId: string): void };
};

const NavPrompt = ({ chatId, chatPrompt, chatIndex, onChatRemoving }: ParamsType) => {
  const { responseFromAPI } = useRequest();
  const dispatch = useDispatch();
  const currentSession = useSelector(selectCurrentSession);
  const [chat, setChat] = useState<ChatType | null>(null);
  const [isNewChat, setIsNewChat] = useState<boolean>(false);
  const { deleteChatApi, getChat, renameChatsApi } = useChats();

  const [chatName, setChatName] = useState(chat?.name || chatPrompt);
  const navPromptHref = `navPrompt-${kebabCase(chatName)}`;

  const { resultIndexes, isSearch } = useSearch();
  const toHide = isSearch && !resultIndexes.includes(chatIndex); // for search

  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteConfirmShown, setDeleteConfirmShown] = useState(false);

  const [editClicked, setEditClicked] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState(chatName || '');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      editConfirm();
    }
  };

  function deleteClick() {
    setDeleteClicked(true);
    setEditClicked(false);
  }

  const editClick = useCallback(() => {
    setDeleteClicked(false);
    setEditClicked(true);
    // Focus the input field when editClicked becomes true
    // (Timeout with 0ms allows any pending rendering updates to complete)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [inputRef]);

  const editConfirm = useCallback(() => {
    if (chatId) {
      renameChatsApi(chatId, editedPrompt).then(() => {
        setEditClicked(false);
      });
    }
  }, [renameChatsApi, chatId, editedPrompt]);

  const deleteConfirm = useCallback(
    (e: any) => {
      e.preventDefault();
      if (chatId) {
        onChatRemoving(chatId as string);
        deleteChatApi(chatId).then(() => {
          setDeleteClicked(false);
        });
      }
    },
    [chatId, deleteChatApi, onChatRemoving]
  );

  const deleteCancel = useCallback(() => {
    setDeleteConfirmShown(() => false);
    setTimeout(() => setDeleteClicked(false), 200);
  }, []);

  useEffect(() => {
    setDeleteClicked(false);
    setEditClicked(false);
  }, [currentSession, chatId]);

  useEffect(() => {
    setChat(() => getChat(chatIndex) || null);
  }, [chatIndex, getChat]);

  useEffect(() => {
    setChatName(() => chat?.name || chatPrompt);
  }, [chat, chatPrompt]);

  useEffect(() => {
    setIsNewChat(() => !!chat && chat.name === null);
  }, [chat]);

  useEffect(() => {
    if (deleteClicked) {
      setDeleteConfirmShown(() => true);
    }
  }, [deleteClicked]);

  return (
    <div className="navPrompt-wrapper">
      <div
        className={clsx('navPrompt', currentSession === chatIndex && 'selected', toHide && 'd-none')}
        onClick={() => {
          !responseFromAPI && dispatch(setCurrentSession(chatIndex));
        }}
      >
        <a href={`#${navPromptHref}`}>
          <svg
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ECECF1"
            stroke="#ECECF1"
            width={16}
            height={16}
          >
            <path
              fill="#ECECF1"
              fillRule="evenodd"
              d="M15 3.25A2.25 2.25 0 0 0 12.75 1h-9.5A2.25 2.25 0 0 0 1 3.25v11a.75.75 0 0 0 1.26.55l2.801-2.6a.75.75 0 0 1 .51-.2h7.179A2.25 2.25 0 0 0 15 9.75v-6.5zm-2.25-.75a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-.75.75H5.572a2.25 2.25 0 0 0-1.531.6L2.5 12.53V3.25a.75.75 0 0 1 .75-.75h9.5z"
              clipRule="evenodd"
            />
          </svg>
          {editClicked ? (
            <input
              id="editInput"
              key="editInput"
              type="text"
              value={editedPrompt}
              onChange={(e) => setEditedPrompt(e.target.value)}
              ref={inputRef}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }} // Add this line to stop propagation
              autoComplete="off"
              onKeyDown={handleKeyPress as unknown as React.KeyboardEventHandler<HTMLInputElement>} // Add this line to handle Enter key press
            />
          ) : (
            <p
              onClick={(e) => {
                dispatch(setShowMenu(false));
              }}
            >
              {chatName.split('').map((char, idx) => (
                <span key={idx} style={{ animationDelay: `${idx * 0.01}s` }}>
                  {char}
                </span>
              ))}
            </p>
          )}
          <div className="toolBox">
            {currentSession === chatIndex && editClicked && (
              <>
                <AiOutlineCheck color="white" className="iconClick" onClick={editConfirm} />
                <RxCross2 color="white" className="iconClick" onClick={() => setEditClicked(false)} />
              </>
            )}

            {!isNewChat && currentSession === chatIndex && !deleteClicked && !editClicked && (
              <>
                <AiOutlineEdit className="iconContainer iconClick" color="white" onClick={editClick} />
                <AiOutlineDelete className="iconContainer iconClick" color="white" onClick={deleteClick} />
              </>
            )}
          </div>
        </a>
      </div>
      {currentSession === chatIndex && deleteClicked && (
        <div className={clsx('delete-confirm-box-wrapper', 'toolBox', deleteConfirmShown && 'opened')}>
          <div className={'delete-confirm-box'}>
            <AiOutlineCheck color="white" className="iconClick" onClick={deleteConfirm} />
            <RxCross2 color="white" className="iconClick" onClick={deleteCancel} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavPrompt;
