import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import clsx from 'clsx';
import { createEscapeHandler } from '../utils/hot-toggle';
import './ModalSlide.scss';

type ParamsType = {
  bodyClassName?: string | undefined;
  children: ReactNode;
  isOpened: boolean;
  modalClassNames: object;
  onModalClose: () => void;
  title: string;
};

function ModalSlide({
  children,
  isOpened,
  onModalClose,
  title,
  modalClassNames = {},
  bodyClassName = undefined
}: ParamsType) {
  const [modalHiddenRight, setModalHiddenRight] = useState(isOpened);
  const backdropClsx = clsx({
    'modalslide-backdrop': true,
    'modalslide-backdrop-transparent': modalHiddenRight
  });
  const modalClx = clsx({
    'modalslide-modal': true,
    'hidden-right': modalHiddenRight,
    ...modalClassNames
  });

  const closeModal = useCallback(() => {
    setModalHiddenRight(() => true);
    setTimeout(() => {
      if (onModalClose) {
        onModalClose();
      }
    }, 200);
  }, [onModalClose]);

  useEffect(() => {
    setTimeout(() => {
      setModalHiddenRight(() => false);
    }, 100);
  }, []);

  useEffect(() => {
    if (!isOpened) {
      closeModal();
    }
  }, [isOpened, closeModal]);

  useEffect(() => {
    return createEscapeHandler(closeModal);
  }, [closeModal]);

  return (
    <div>
      <div className={backdropClsx} onClick={closeModal}></div>
      <div className={modalClx}>
        <div className={clsx('modalslide-body', bodyClassName)}>
          <div className="modalslide-top-row">
            <div className="modalslide-title">{title}</div>
            <button className="modalslide-close" type="button" onClick={closeModal}>
              <RxCross2 color="white" className="iconClick" style={{ fontSize: '25px' }} />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalSlide;
