import { AuthProviders } from '../../utils/auth';

export function prepareGoogleCredentials(user) {
  const allowedDomains = process.env.REACT_APP_LOGIN_DOMAIN.split(',');

  if (!allowedDomains.some((suffix) => user.email.endsWith(suffix))) {
    throw new Error('Unexpected email address');
  }
  return { ...user.toJSON(), provider: AuthProviders.FIREBASE };
}
