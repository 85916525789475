import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { setCurrentSession, setShowMenu } from '../data/ui';
import { useChats } from '../hooks/useChats';
import { createEmptyFEChat } from '../data/chats-remote';

const NewChat = () => {
  const dispatch = useDispatch();
  const { chatList: chats } = useChats();
  const { addChatApi: addChat, newChatIndex } = useChats();
  const [hasNewChat, setHasNewChat] = useState(newChatIndex !== null);
  const [buttonClsx, setButtonClx] = useState({ sideMenuButton: true, 'has-new-chat': hasNewChat });

  const onClick = useCallback(() => {
    if (hasNewChat) {
      dispatch(setCurrentSession(newChatIndex));
    } else {
      addChat(createEmptyFEChat())
        .unwrap()
        .then(() => {
          const newCurrentSession = chats?.length || 1;
          dispatch(setCurrentSession(newCurrentSession));
          dispatch(setShowMenu(false));
        });
    }
  }, [addChat, hasNewChat, newChatIndex, dispatch, chats]);

  useEffect(() => {
    setHasNewChat(() => newChatIndex !== null);
  }, [newChatIndex]);

  useEffect(() => {
    setButtonClx((classes) => ({ ...classes, 'has-new-chat': hasNewChat }));
  }, [hasNewChat]);

  return (
    <div className={clsx(buttonClsx)} onClick={onClick}>
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
