import React from 'react';

type Props = {
  children: React.ReactNode;
  bg: string;
  className: string;
};

const Avatar = ({ children, bg, className }: Props) => {
  return (
    <div className="chatlog-avatar" style={{ backgroundColor: `${bg}` }}>
      <div className={className}>{children}</div>
    </div>
  );
};

export default Avatar;
