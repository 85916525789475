import React from 'react';
import './form.css';

import CompanyLogo from '../CompanyLogo';
import GoogleSignIn from './GoogleSignIn';
import MicrosoftSignIn from './MicrosoftSignIn';
import { OKTA_AUTH_ENABLED } from '../../utils/auth';
import OktaSignIn from './OktaSignIn';

const SignupForm = () => (
  <div className="signupFormContainer">
    <div style={{ height: '12%' }}>
      <CompanyLogo />
    </div>
    <h1>Welcome to {process.env.REACT_APP_COMPANY} Bot</h1>
    {OKTA_AUTH_ENABLED ? (
      <OktaSignIn />
    ) : (
      <>
        {process.env.REACT_APP_GOOGLE_AUTH_ENABLED === 'true' && <GoogleSignIn />}
        {process.env.REACT_APP_MICROSOFT_AUTH_ENABLED === 'true' && <MicrosoftSignIn />}
      </>
    )}
  </div>
);

export default SignupForm;
