import React from 'react';

const logo_white = require(`../assets/${process.env.REACT_APP_COMPANY}-logo-white.png`);

const CompanyLogo = () => {
  return (
    <div className="company-logo-wrapper">
      <img
        src={logo_white}
        alt="company-logo"
        style={{
          // height: "100px",
          width: '200px'
        }}
      />
    </div>
  );
};

export default CompanyLogo;
