import Dropdown from 'react-bootstrap/Dropdown';

type Props = {
  title: string;
  options: Array<{ label: string; onClick: () => void }>;
};

export default function NextDropdown({ title, options }: Props) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-secondary" size="sm">
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map(({ label, onClick }) => (
          <Dropdown.Item key={`llm:${label}`} onClick={onClick}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
