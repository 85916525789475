import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setUser } from '../ui';

import { setResponseFromAPI } from '../makeRequest';
import { authHeaders, refreshAuthToken } from '../../utils/auth';

const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_URL });

type FetchBaseArgType = {
  url: string;
  method: string;
  body?: object | string | undefined;
};

const fetchBase = async (baseArgs: FetchBaseArgType, api: any, extraOptions: any) => {
  const { method, body: baseBody, ...args } = baseArgs;
  const contentType = method === 'get' ? undefined : 'application/json';
  const prepareHeaders = {
    ...authHeaders(),
    'Content-Type': contentType
  };
  const body = baseBody === undefined ? baseBody : JSON.stringify(baseBody);
  const fetchArgs = { ...args, method, body, headers: prepareHeaders };

  return baseQuery({ ...fetchArgs }, api, extraOptions);
};

export const baseQueryWithReAuth = async (baseArgs: FetchBaseArgType, api: any, extraOptions: any) => {
  let result;
  const skipBlockingApi = extraOptions?.skipBlocking;
  try {
    if (!skipBlockingApi) {
      api.dispatch(setResponseFromAPI(true));
    }
    result = await fetchBase(baseArgs, api, extraOptions);
    if (result.error && result.error.status === 401) {
      await refreshAuthToken();
      result = await fetchBase(baseArgs, api, extraOptions);
    }
  } catch (e: unknown) {
    result = { error: e };
  } finally {
    // @ts-ignore
    if (result?.error && result?.error?.status === 401) {
      api.dispatch(setUser(null));
    }
  }
  if (!skipBlockingApi) {
    api.dispatch(setResponseFromAPI(false));
  }
  return result;
};
