import React, { useCallback } from 'react';
import { TbPrompt } from 'react-icons/tb';
import type { ModalDetailsType } from './modalsbar-types';

type ParamsType = {
  title: string;
  onPromptSelected: (modalDetails: ModalDetailsType) => void;
  promptDetailsList: Array<ModalDetailsType>;
};

function ModalsBarBlock({ title, onPromptSelected, promptDetailsList }: ParamsType) {
  const onPromptClick = useCallback(
    (promptDetail: ModalDetailsType) => {
      onPromptSelected(promptDetail);
    },
    [onPromptSelected]
  );

  return promptDetailsList.length > 0 ? (
    <div className="modalBarBlock">
      <div className="modalBarBlockTitle">{title}</div>
      <div className="navPromptWrapper prompt-list">
        {promptDetailsList.map((promptDetail, index) => (
          <div
            key={`promptDetail.prompt-${promptDetail.prompt}`}
            className="navPrompt"
            onClick={() => onPromptClick(promptDetail)}
          >
            <TbPrompt />
            <p>{promptDetail.prompt}</p>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ModalsBarBlock;
