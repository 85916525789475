import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { RxCross2, RxCheckCircled } from 'react-icons/rx';
import { useSync } from '../hooks/useSync';
import './SyncInfo.scss';

function SyncInfo() {
  const { inSync, syncLeft, syncTotal, syncFailedNum } = useSync();
  const [syncDone, setSyncDone] = useState(0);
  const [displayed, setDisplayed] = useState(false);
  const [shown, setShown] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const close = useCallback(() => {
    setTimeout(() => {
      setShown(() => false);

      setTimeout(() => {
        setDisplayed(() => false);
      }, 200);
    }, 200);
  }, []);

  const onClick = useCallback(() => {
    if (syncDone) {
      close();
    }
  }, [close, syncDone]);

  useEffect(() => {
    if (syncDone) {
      setShowResult(() => true);
    }
  }, [syncDone]);

  useEffect(() => {
    setTimeout(() => {
      setSyncDone(() => syncTotal - syncLeft);
    }, 100);
  }, [syncLeft, syncTotal]);

  useEffect(() => {
    if (inSync) {
      setDisplayed(() => true);
      setTimeout(() => {
        setShown(() => true);
      }, 200);
    }
  }, [inSync, syncFailedNum]);

  return displayed ? (
    <div className={clsx({ 'sync-info': true, shown, cursor: syncDone })} onClick={onClick}>
      <h3>Saving data to new storage</h3>
      {!syncDone && (
        <div className="sync-info-message">
          <div>Sync chats with remote storage {`${syncDone}/${syncTotal}`}</div>
          <div className="sync-info-loading"></div>
        </div>
      )}
      <div className={clsx({ 'sync-result': true, 'show-result': showResult })}>
        <div className="sync-result-info">
          {syncFailedNum ? (
            <>
              <div className="sync-result-icon">
                <RxCross2 size={40} strokeWidth={'3'} color={'red'} />
              </div>
              <div>
                Failed to sync {syncFailedNum} of {syncTotal} chats.
              </div>
            </>
          ) : (
            <>
              <div className="sync-result-icon">
                <RxCheckCircled size={40} strokeWidth={'1'} color={'green'} />
              </div>
              <div>Chats synchronized successfully!</div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default SyncInfo;
