import React, { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavLinksContainer from './NavLinksContainer';
import NavPrompt from './NavPrompt';
import NewChat from './NewChat';
import SearchInput from './SearchInput';
import useSearch from '../hooks/useSearch';
import { useChats } from '../hooks/useChats';
import { selectCurrentSession, setCurrentSession } from '../data/ui';

type ParamsType = {
  children?: ReactNode;
};

export default function NavContent({ children }: ParamsType) {
  const { chatList } = useChats();
  const dispatch = useDispatch();
  const currentSession = useSelector(selectCurrentSession);

  // reindex chatLog to search index
  const { reindex } = useSearch();

  const onChatDeleting = useCallback(
    (chatId: string) => {
      if (chatList.length === 1) {
        dispatch(setCurrentSession(0));
      } else {
        let currentChatIndex = currentSession;
        const chatIndex = chatList.findIndex(({ id }) => id === chatId);
        const checkList = [...chatList];
        checkList.splice(chatIndex, 1);
        while (checkList[currentChatIndex] === undefined) {
          currentChatIndex--;
          if (currentChatIndex === 0) {
            break;
          }
        }
        dispatch(setCurrentSession(currentChatIndex));
      }
    },
    [chatList, currentSession, dispatch]
  );

  useEffect(() => {
    if (chatList) {
      reindex(chatList.map(({ chatLog, name }) => [chatLog, name]));
    }
  }, [chatList, reindex]);

  return (
    <>
      <div className="navItems-chats">
        <SearchInput />
        <NewChat />
        <div className="navPromptWrapper chat-logs">
          {chatList?.map(({ chatLog, name, files, id }, index) => (
            <NavPrompt
              key={`navPrompt-${name}-${id}`}
              chatIndex={index}
              chatId={id}
              chatPrompt={name?.substring(0, 25) || 'New chat'}
              onChatRemoving={onChatDeleting}
            />
          ))}
        </div>
      </div>
      {children}
      <NavLinksContainer />
    </>
  );
}
