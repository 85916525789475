import type { CommandExecutor, HandlesRequestInterface, LlmInterface, LlmsInterface } from './types';
import type { ActionPayloadType, BaseActionType } from './types';
import type { ChatType } from '../chats-type';

const AVAILABLE_MODAL_PROMPTS = ['/adjust tone', '/explain', '/ideate', '/summarise'];

const createCommandExecutor = (
  actionCall: { (): Promise<string> },
  preUpdateCall: undefined | { (chat: ChatType): Promise<ChatType> } = undefined
): CommandExecutor => {
  return { execute: () => actionCall(), beforeReplaceChat: preUpdateCall };
};

/**
 * Looks for a matching command in the provided Action Map and returns the corresponding action.
 * If no matching command is found, it returns the default action.
 *
 * @return {Function} - A function that calls the api and returns Promise.
 */
export function discoverCommandExecutor(
  llm: LlmInterface,
  { inputPrompt, files, ...payload }: ActionPayloadType & { inputPrompt: string }
): CommandExecutor {
  for (const action of llm.actions) {
    const actionHandles = action as HandlesRequestInterface;
    if (actionHandles.handlesRequest && actionHandles.handlesRequest.check({ inputPrompt, files, ...payload })) {
      const params = actionHandles.handlesRequest.buildActionParams({ inputPrompt, files, ...payload });
      return createCommandExecutor(() => action(params), actionHandles.handlesRequest.preUpdateChat);
    }
    const commandAction = action as BaseActionType;
    if (commandAction.command && inputPrompt.startsWith(commandAction.command)) {
      const message = inputPrompt.substring(commandAction.command.length);
      return createCommandExecutor(() =>
        action({
          ...payload,
          message
        })
      );
    }
  }
  const action = llm.defaultAction;
  return createCommandExecutor(() =>
    action({
      ...payload,
      message: inputPrompt
    })
  );
}

/**
 * Retrieves the commands from the given LLM (Language Learning Model).
 *
 * @param {LlmsInterface} llm - The LLM(Language Learning Model) module.
 * @returns {Array} - An array of commands.
 */
export function getCommands(llm: LlmInterface) {
  return Object.values(llm.actions)
    .filter(({ onlyBrands = [] }) => !onlyBrands || onlyBrands.indexOf(process.env.REACT_APP_COMPANY as string) !== -1)
    .map((action) => (action as BaseActionType).command)
    .concat(AVAILABLE_MODAL_PROMPTS)
    .filter((command) => !!command);
}

export function getModalCommands(llm: LlmInterface): Array<string> {
  return Object.values(llm.actions)
    .reduce((cmds, { command, isModal }) => {
      return !!command && isModal ? [...cmds, command as string] : cmds;
    }, [] as Array<string>)
    .concat(AVAILABLE_MODAL_PROMPTS);
}

/**
 * Retrieves options from the given llms object.
 *
 * @param {Object} llms - An object containing LLMs (Learning Management Modules).
 * @return {Array} - An array of options, each containing a key and label property.
 */
export function getOptions(llms: LlmsInterface) {
  return llms.all().map(([key, llm]) => ({
    key,
    label: llm.NAME
  }));
}
