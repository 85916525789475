import React, { useCallback } from 'react';
import AutocompleteSuggestionsList from './AutocompleteSuggestionsList';
import { useSelector } from 'react-redux';
import { showingSuggestionsSelector } from '../data/prompts/suggestions-slice';

type Params = {
  onSuggestionClick: (suggestion: string) => void;
};

const AutocompleteSuggestions = ({ onSuggestionClick }: Params) => {
  const showingSuggestions = useSelector(showingSuggestionsSelector);

  const onItemClick = useCallback(
    (suggestion: string) => {
      onSuggestionClick(suggestion);
    },
    [onSuggestionClick]
  );

  return (
    <div className="autocomplete-suggestions">
      {showingSuggestions && <AutocompleteSuggestionsList onItemClick={onItemClick} />}
    </div>
  );
};

export default AutocompleteSuggestions;
