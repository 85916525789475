import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULT_LLM_KEY } from './llm/types';

function chatKey(userId) {
  return `chatLog_${userId}`;
}

function nameKey(userId) {
  return `chatNames_${userId}`;
}

function filesKey(userId) {
  return `fileIds_${userId}`;
}

function llmsKey(userId) {
  return `llmKeys_${userId}`;
}

function JSONParse(data) {
  try {
    return JSON.parse(data) || [];
  } catch (err) {
    console.log('JSONParse', err);
  }
  return [];
}

function serializeFiles(files) {
  // TODO: add support of multiple files
  const [file] = files || [];
  if (!file) {
    return files;
  }
  return [{ fileId: files[0], fileName: files[1] }];
}

function deserializeFiles(files) {
  // TODO: add support of multiple files
  const [file] = files || [];
  if (!file) {
    return files;
  }
  return [file.fileId, file.fileName];
}

function getItems(userId) {
  const chats = JSONParse(localStorage.getItem(chatKey(userId)));
  const names = JSONParse(localStorage.getItem(nameKey(userId)));
  const files = JSONParse(localStorage.getItem(filesKey(userId)));
  const llms = JSONParse(localStorage.getItem(llmsKey(userId)));

  return chats.map((chatLog, i) => ({
    id: i,
    chatLog,
    name: names[i],
    files: serializeFiles(files[i]),
    llm: llms[i] === undefined ? DEFAULT_LLM_KEY : llms[i]
  }));
}

function setItems(userId, data) {
  const { chats, names, files, llms } = data.reduce(
    (res, { chatLog, name, files, llm }) => ({
      chats: [...(res.chats || []), chatLog],
      names: [...(res.names || []), name],
      files: [...(res.files || []), deserializeFiles(files)],
      llms: [...(res.llms || []), llm === undefined ? DEFAULT_LLM_KEY : llm]
    }),
    {}
  );
  localStorage.setItem(chatKey(userId), JSON.stringify(chats || []));
  localStorage.setItem(nameKey(userId), JSON.stringify(names || []));
  localStorage.setItem(filesKey(userId), JSON.stringify(files || []));
  localStorage.setItem(llmsKey(userId), JSON.stringify(llms || []));
}

export const chatsLegacyApi = createApi({
  reducerPath: 'chatsLegacyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://example.backend/api/v2/' // change it to real api if we start using BE rest api
  }),
  tagTypes: ['LIST', 'LegacyChat'],
  endpoints: (builder) => ({
    getChats: builder.query({
      queryFn: ({ userId }) => {
        return { data: getItems(userId) || [] };
      },
      providesTags: ['LIST']
    }),
    setChats: builder.mutation({
      queryFn: ({ userId, chats }) => {
        setItems(userId, chats);
        return { data: chats };
      },
      invalidatesTags: ['LIST', 'LegacyChat']
    }),
    getChat: builder.query({
      queryFn: ({ userId, chatIndex }) => {
        return { data: (getItems(userId) || [])[chatIndex] };
      },
      providesTags: (_, __, { userId, chatIndex }) => [{ type: 'LegacyChat', id: cacheKey(userId, chatIndex) }]
    }),
    getNewChatIndex: builder.query({
      queryFn: ({ userId }) => {
        const chat = getItems(userId).findIndex(({ name }) => name === null);
        return { data: chat === -1 ? null : chat };
      },
      providesTags: ['LIST']
    }),
    addChat: builder.mutation({
      queryFn: ({ userId, name, chatLog, files, llm }) => {
        const chats = getItems(userId) || [];
        const chat = { name, chatLog, files, chatIndex: chats.length, llm: llm || null };
        const updated = [...chats, chat];
        setItems(userId, updated);
        return { data: chat };
      },
      invalidatesTags: (__, _, { userId }) => ['LIST']
    }),
    replaceChat: builder.mutation({
      queryFn: ({ userId, chatIndex, payload }) => {
        const chats = getItems(userId) || [];
        chats.splice(chatIndex, 1, payload);
        setItems(userId, chats);
        return { data: chats };
      },
      invalidatesTags: (_, __, { userId, chatIndex }) => [
        { type: 'LIST' },
        { type: 'LegacyChat', id: cacheKey(userId, chatIndex) }
      ]
    }),
    deleteChat: builder.mutation({
      queryFn: ({ userId, chatIndex }) => {
        const chats = getItems(userId) || [];
        chats.splice(chatIndex, 1);
        setItems(userId, chats);
        return { data: chats };
      },
      invalidatesTags: (_, __, { userId, chatIndex }) => [
        { type: 'LIST' },
        { type: 'LegacyChat', id: cacheKey(userId, chatIndex) }
      ]
    })
  })
});

export const {
  useGetNewChatIndexQuery,
  useGetChatsQuery,
  useSetChatsMutation,
  useAddChatMutation,
  useReplaceChatMutation,
  useDeleteChatMutation
} = chatsLegacyApi;

function cacheKey(userId, chatId) {
  return `${userId}:${chatId}`;
}
