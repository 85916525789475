import React from 'react';
import { useSelector } from 'react-redux';
import { FaRegUser } from 'react-icons/fa';

import CompanyLogo from './CompanyLogo';
import NavUserExpandable from './NavUserExpandable';
import { selectCurrentUser } from '../data/ui';

export default function NavLinksContainer() {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <div className="navLinks">
      <CompanyLogo />
      <NavUserExpandable svg={<FaRegUser />} text={currentUser.displayName} />
    </div>
  );
}
