import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  streamReadMessage,
  abortController,
  selectResponseFromAPI,
  selectCurrentBotMessage,
  selectCanStopResponse,
  setCanStopResponse as setCanStopResponseAction,
  setCurrentBotMessage as setCurrentBotMessageAction,
  setResponseFromAPI as setResponseFromAPIAction
} from '../data/makeRequest';
import llms, { DEFAULT_LLM_KEY } from '../data/llm';
import { getCommands, getModalCommands } from '../data/llm/utils';
import { refreshAuthToken } from '../utils/auth';
import type { CommandExecutor, LlmInterface } from '../data/llm/types';

const getErrorResponseMessage = (err: string): string =>
  `ERROR: ${err} \n Please report the above error to Tech Support (labs@next15.com ).`;

export function useRequest({ llmKey = DEFAULT_LLM_KEY } = {}) {
  // const llm = llmKey ? llms[llmKey] || llms.default : llms.default;
  // const llm: LlmInterface = llms[llmKey];
  const llm: LlmInterface = llms.llm(llmKey);

  const dispatch = useDispatch();
  const responseFromAPI = useSelector(selectResponseFromAPI);
  const currentBotMessage = useSelector(selectCurrentBotMessage);
  const canStopResponse = useSelector(selectCanStopResponse);

  const setCanStopResponse = useCallback((value: boolean) => dispatch(setCanStopResponseAction(value)), [dispatch]);
  const setCurrentBotMessage = useCallback((value: string) => dispatch(setCurrentBotMessageAction(value)), [dispatch]);
  const setResponseFromAPI = useCallback((value: boolean) => dispatch(setResponseFromAPIAction(value)), [dispatch]);

  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState(getCommands(llm));

  const streamResponse = useCallback(
    async (response: Response) => {
      try {
        console.log('Response status:', response.status); // Log response status
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const botMessage = await streamReadMessage(response, (message: string) => {
          setCurrentBotMessage(message);
        });
        setCanStopResponse(false);
        return botMessage;
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          setCurrentBotMessage(getErrorResponseMessage(err));
        }
        setResponseFromAPI(false);
      }
    },
    [setResponseFromAPI, setCurrentBotMessage, setCanStopResponse]
  );

  const reauthRequest = useCallback(async (callRequest: { (): Promise<any> }) => {
    let response;
    let error;
    try {
      response = await callRequest();
      // console.log('response.status', response.status);
    } catch (err: any) {
      if (err.response?.status === 401) {
        await refreshAuthToken();
        response = await callRequest().catch((err: any) => console.log(err));
      }
      error = err;
    }

    if (response) {
      return response;
    }

    if (error) {
      throw error;
    }
  }, []);

  const call = useCallback(
    async (commandExecutor: CommandExecutor) => {
      // console.log('LLM used', llm.NAME);

      try {
        setCanStopResponse(true);
        // const commandExecutor = discoverCommandExecutor(llm, { inputPrompt, ...payload });
        const response = await reauthRequest(commandExecutor.execute);
        return await streamResponse(response);
      } catch (err: any) {
        if (err.name !== 'AbortError') {
          setCurrentBotMessage(getErrorResponseMessage(err));
        }
        setResponseFromAPI(false);
      }

      setCanStopResponse(false);
    },
    [setCanStopResponse, setCurrentBotMessage, setResponseFromAPI, streamResponse, reauthRequest]
  );

  const stop = useCallback(() => {
    if (canStopResponse) {
      setCurrentBotMessage('');
      abortController?.abort();
      setCanStopResponse(false);
      return currentBotMessage;
    }
  }, [canStopResponse, setCurrentBotMessage, setCanStopResponse, currentBotMessage]);

  const isModalPrompt = useCallback(
    (prompt: string) => {
      return getModalCommands(llm).includes(prompt);
    },
    [llm]
  );

  useEffect(() => {
    setAutocompleteSuggestions(() => getCommands(llm));
  }, [llm]);

  return {
    call,
    stop,
    currentBotMessage,
    setCurrentBotMessage,
    canStopResponse,
    setCanStopResponse,
    responseFromAPI,
    setResponseFromAPI,
    isModalPrompt,
    autocompleteSuggestions,
    streamResponse,
    reauthRequest
  };
}
