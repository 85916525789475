import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../data/ui';
import { useGetChatsQuery as useLegacyGetChatsQuery } from '../data/chats';
import {
  useGetChatsQuery,
  useAddChatSyncMutation,
  startSync,
  selectSyncTotal,
  selectSyncLeft,
  selectInSync,
  finishSync,
  selectSyncIsDone,
  selectSyncFailed
} from '../data/chats-remote';
import { clearAllButUser } from '../utils/storage';
import type { ChatType } from '../data/chats-type';

export function useSync() {
  const currentUser = useSelector(selectCurrentUser);
  const [remoteAddChat] = useAddChatSyncMutation();
  const { data: legacyChats } = useLegacyGetChatsQuery({ userId: currentUser.uid });
  // @ts-ignore
  const { data: remoteChats } = useGetChatsQuery();
  const dispatch = useDispatch();
  const syncTotal = useSelector(selectSyncTotal);
  const syncLeft = useSelector(selectSyncLeft);
  const inSync = useSelector(selectInSync);
  const syncFailedNum = useSelector(selectSyncFailed);
  const syncWasDone = useSelector(selectSyncIsDone);

  const initSync = useCallback(
    (legacyChats: Array<ChatType>, remoteChats: Array<ChatType>) => {
      const toSync = legacyChats.filter(
        (legacyChat) =>
          legacyChat.name !== null &&
          remoteChats.findIndex(({ name }, sortIndex) => name === legacyChat.name && sortIndex === legacyChat.id) === -1
      );

      if (toSync.length > 0) {
        dispatch(startSync({ total: toSync.length }));
        for (const legacyChat of toSync) {
          remoteAddChat({ ...legacyChat });
        }
      } else {
        dispatch(finishSync());
      }
    },
    [dispatch, remoteAddChat]
  );

  const safeStartSync = useCallback(() => {
    if (!syncWasDone) {
      if (!inSync && legacyChats && remoteChats) {
        initSync(legacyChats, remoteChats as Array<ChatType>);
      }
    }
  }, [inSync, legacyChats, initSync, remoteChats, syncWasDone]);

  useEffect(() => {
    if (syncTotal !== 0 && syncLeft === 0) {
      clearAllButUser();
      dispatch(finishSync());
    }
  }, [dispatch, syncLeft, syncTotal]);

  return {
    inSync,
    syncLeft,
    syncTotal,
    safeStartSync,
    syncFailedNum
  };
}
