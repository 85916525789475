import React from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { MdLogout } from 'react-icons/md';

import { OKTA_AUTH_ENABLED } from '../utils/auth';
import { setUser } from '../data/ui';
import NavLinks from './NavLink';

function LogoutButton({ onClick }: { onClick: { (): void } }) {
  return <NavLinks svg={<MdLogout />} text="Log out" link="" onClick={onClick} />;
}

function OktaLogout() {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  return (
    <LogoutButton
      onClick={() => {
        oktaAuth.signOut().then(() => dispatch(setUser(null)));
      }}
    />
  );
}

function FirebaseLogout() {
  const dispatch = useDispatch();
  return <LogoutButton onClick={() => dispatch(setUser(null))} />;
}

export default function Logout() {
  return OKTA_AUTH_ENABLED ? <OktaLogout /> : <FirebaseLogout />;
}
