import React, { LegacyRef, useEffect, useRef } from 'react';

type Params = {
  progressPercentage: number;
};

function ProgressLoaderCircle({ progressPercentage }: Params) {
  const progressSvgRef = useRef<SVGSVGElement | undefined>(undefined);

  useEffect(() => {
    const elem = progressSvgRef?.current;
    if (elem) {
      elem.setAttribute('style', `--dash: calc((${progressPercentage} * var(--circumference)) / 100)`);
    }
  }, [progressSvgRef, progressPercentage]);

  return (
    <div className="progress-loader">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="circular-progress"
        ref={progressSvgRef as LegacyRef<SVGSVGElement> | undefined}
      >
        <circle className="bg"></circle>
        <circle className="fg"></circle>
      </svg>
      <div className="progress-numbers">{progressPercentage}</div>
    </div>
  );
}

export default ProgressLoaderCircle;
