import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import SvgComponent from '../components/SvgComponent';
import Avatar from '../components/Avatar';

const Loading = () => {
  const override = {
    color: '#fff',
    loading: true
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ paddingTop: '16px' }}>
        <Avatar bg="#11a27f" className="openaiSVG">
          {/*<SvgComponent w={41} h={41} style={{ paddingTop: '10px' }} />*/}
          <SvgComponent w={41} h={41} />
        </Avatar>
      </div>
      <div style={{ paddingLeft: '24px' }}>
        <PulseLoader
          color={override.color}
          loading={override.loading}
          cssOverride={override}
          size={5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default Loading;
