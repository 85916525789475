import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

export default function OktaSignIn() {
  const { oktaAuth } = useOktaAuth();

  const login = async () => oktaAuth.signInWithRedirect();

  return <button onClick={login}>Login with OKTA</button>;
}
