import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import useSuggestions from '../hooks/useSuggestions';
import createOutSideHandler from '../utils/hot-toggle';
import { filteredSuggestionsSelector, highlightedSuggestionIndexSelector } from '../data/prompts/suggestions-slice';

type Params = {
  onItemClick: (suggestion: string) => void;
};

function AutocompleteSuggestionsList({ onItemClick }: Params) {
  const filteredSuggestions = useSelector(filteredSuggestionsSelector);
  const highlightedSuggestionIndex = useSelector(highlightedSuggestionIndexSelector);
  const { turnOffSuggestions } = useSuggestions();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const createItemOnClick = useCallback(
    (suggestion: string) => {
      return () => {
        if (onItemClick) {
          onItemClick(suggestion);
        }
      };
    },
    [onItemClick]
  );

  const onClose = useCallback(() => {
    turnOffSuggestions();
  }, [turnOffSuggestions]);

  useEffect(() => {
    return createOutSideHandler(wrapperRef, onClose);
  }, [onClose, wrapperRef]);

  return (
    <div ref={wrapperRef}>
      {filteredSuggestions.map((suggestion, index) => (
        <div
          className={clsx('suggestion-item', index === highlightedSuggestionIndex && 'selected-suggestion')}
          key={index}
          onClick={createItemOnClick(suggestion)}
        >
          {suggestion}
        </div>
      ))}
    </div>
  );
}

export default AutocompleteSuggestionsList;
