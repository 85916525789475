import React, { useCallback, useEffect, useRef, useState, memo, ChangeEvent } from 'react';

const TEXT_LABEL_DEFAULT = 'Article content to summarise';
const TEXTAREA_PLACEHOLDER_DEFAULT = "Use 'Get text' button or paste the article text here...";

export const ARTICLE_STATUSES = { START: -1, OK: 0, EDITING: 1, LOADING: 2, EMPTY_VALUE: 3, LOADED_BASE: 4, ERROR: 5 };

export const URL_NO_PROTOCOL_REGEX_GLOBAL = /(https?:\/\/)?[a-zA-Z0-9.]+[ :;\\!?]{0}\.[a-zA-Z]{2,}(\/?.*[^)(\\.,])/g;
export const URL_NO_PROTOCOL_REGEX = /^(https?:\/\/)?[a-zA-Z0-9.]+[ :;\\!?]{0}\.[a-zA-Z]{2,}(\/?.*)$/;

export function isValidUrl(urlValue: string): [boolean | null, string] {
  let isValid: boolean | null;
  let url: string | URL = '';
  const urlTrim = urlValue.trim();

  if (!urlTrim) {
    isValid = null;
  } else if (urlTrim && URL_NO_PROTOCOL_REGEX.test(urlTrim)) {
    const urlStr = /^https?:.*/.test(urlTrim) ? urlTrim : `https://${urlTrim}`;
    try {
      url = new URL(urlStr);
      isValid = true;
    } catch (e) {
      isValid = false;
    }
  } else {
    isValid = false;
  }
  return [isValid, String(url)];
}

type FormParams = {
  link: string;
  onChanged: ({ link, text }: { link: string; text: string }) => void;
  onConfirm: (url: string) => void;
  onFormItemBlur: () => void;
  onGetTextRequest: (link: string) => void;
  text: string;
  toConfirm: boolean;
  toLoad: boolean;
};

function MHPArticleGetterFormBase({
  link,
  onGetTextRequest,
  toConfirm,
  onChanged,
  onFormItemBlur,
  onConfirm,
  text = '',
  toLoad = false
}: FormParams) {
  const [urlValue, setUrlValue] = useState(link);
  const [textValue, setTextValue] = useState(text);
  const textareaRows = 15;
  const formRef = useRef(null);
  const urlFieldRef = useRef(null);
  const textFieldRef = useRef(null);

  const getText = useCallback(() => {}, []);
  const onSubmit = useCallback(() => {
    onChanged({ text: textValue, link: urlValue });
  }, [onChanged, textValue, urlValue]);

  const onGetTextClick = useCallback(() => {
    onFormItemBlur && onFormItemBlur();
    onGetTextRequest && onGetTextRequest(link);
  }, [link, onFormItemBlur, onGetTextRequest]);

  const fireOnChanged = useCallback(
    (link: string, text: string) => {
      onChanged({ link, text });
    },
    [onChanged]
  );

  const onTextAreaChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setTextValue(e.target.value as string);
      fireOnChanged(urlValue, e.target.value);
    },
    [urlValue, fireOnChanged]
  );

  const onUrlChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUrlValue(e.target.value);
      fireOnChanged(e.target.value, textValue);
    },
    [textValue, fireOnChanged]
  );

  const onConfirmClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      if (onConfirm) {
        onConfirm(urlValue);
      }
    },
    [onConfirm, urlValue]
  ) as unknown;

  useEffect(() => {
    if (toLoad) {
      getText();
    }
  }, [toLoad, getText]);

  useEffect(() => {
    setTextValue(() => text);
  }, [text]);

  // React.FocusEventHandler<HTMLTextAreaElement>
  // const blurCallback = useCallback(
  const blurCallback = useCallback(() => {
    if (onFormItemBlur) {
      onFormItemBlur();
    }
  }, [onFormItemBlur]) as unknown;

  return (
    <form className="modalslide-form mhp-article-getter" ref={formRef} onSubmit={onSubmit}>
      <div className="modalslide-form-group">
        <label>Article URL:</label>
        <div className={'modalslide-form-row mhp-url-row'}>
          <input
            type={'text'}
            className={'mhp-url-field'}
            placeholder="Paste the URL of the article here..."
            value={urlValue}
            onChange={onUrlChange}
            ref={urlFieldRef}
            onBlur={blurCallback as React.FocusEventHandler<HTMLInputElement>}
          />
          <button type={'button'} className="mhp-gettext-btn" onClick={onGetTextClick}>
            Get text
          </button>
        </div>
      </div>
      <div className="modalslide-form-group article-text-block">
        <label>{TEXT_LABEL_DEFAULT}:</label>
        <textarea
          className="modalslide-text"
          placeholder={TEXTAREA_PLACEHOLDER_DEFAULT}
          rows={textareaRows}
          onChange={onTextAreaChange}
          value={textValue}
          ref={textFieldRef}
          onBlur={blurCallback as React.FocusEventHandler<HTMLTextAreaElement>}
        />
      </div>

      {toConfirm && (
        <div className="modalslide-form-buttons">
          <button type="button" onClick={onConfirmClick as React.MouseEventHandler<HTMLButtonElement>}>
            Confirm
          </button>
        </div>
      )}
    </form>
  );
}

const MHPArticleGetterForm = memo(MHPArticleGetterFormBase);

export default MHPArticleGetterForm;
