import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChatLog from './ChatLog';
import { selectCurrentSession } from '../data/ui';
import { useChats } from '../hooks/useChats';
import type { ChatType } from '../data/chats-type';

function ChatArea() {
  const wrapperRef = useRef(null);
  const logRef = useRef<HTMLDivElement>(null);
  const currentSession = useSelector(selectCurrentSession);
  const [chat, setChat] = useState<ChatType | null | undefined>(null);
  const { getChat } = useChats();

  useEffect(() => {
    if (logRef.current && wrapperRef.current && chat) {
      logRef.current.scrollIntoView({
        // behavior: 'instant',
        block: chat.chatLog?.length > 0 ? 'end' : 'start'
      });
    }
  }, [currentSession, logRef, wrapperRef, chat]);

  useEffect(() => {
    setChat(() => getChat(currentSession));
  }, [getChat, currentSession]);

  return (
    <div className="chatLogWrapper" ref={wrapperRef}>
      <div ref={logRef}>
        <ChatLog />
      </div>
    </div>
  );
}

export default ChatArea;
