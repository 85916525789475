import React, { ChangeEvent, useCallback, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import ModalSlide from './ModalSlide';
import SendIcon from './SendIcon';
import './Next15Policies.scss';
import { useInput } from '../hooks/useInput';
import { POLICIES_PROMPT, POLICIES_CHATLOG_DELIMETER } from '../data/llm/openai';

type ParamsType = {
  onClose: {
    (): void;
  };
  onDone: {
    (done: boolean): void;
  };
};

function Next15Policies({ onClose, onDone }: ParamsType) {
  const [modalOpened, setModalOpened] = useState(true);
  // We might want to be able switch model for compares, so keeping switcher here
  const gptModel = 'gpt-4';
  const [textValue, setTextValue] = useState('');
  const [errorTextValue, setErrorTextValue] = useState('');
  const { runInputPrompt } = useInput();

  const onModalClose = useCallback(() => {
    setModalOpened(() => false);
    onClose();
  }, [onClose]);

  const onTextAreaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(() => e.target.value);
    setErrorTextValue(() => '');
  }, []);

  const onSubmit = useCallback(
    (e: Event) => {
      e.preventDefault();
      if (textValue) {
        const delimeter = POLICIES_CHATLOG_DELIMETER;
        const message = `${POLICIES_PROMPT}${delimeter}${gptModel}${delimeter}${textValue}`;
        setModalOpened(() => false);
        runInputPrompt({ inputPrompt: message, onSent: () => onDone(true) });
      } else {
        setErrorTextValue(() => 'Please, add your message');
      }
    },
    [textValue, runInputPrompt, onDone]
  );

  return (
    <ModalSlide
      title={'Next15 policies'}
      isOpened={modalOpened}
      onModalClose={onModalClose}
      modalClassNames={{ 'next15-policies-modal': true }}
    >
      <>
        <div className="modalslide-description">Chat about Next15 policies</div>

        <form className="modalslide-form" onSubmit={onSubmit as unknown as React.FormEventHandler<HTMLFormElement>}>
          <div className="modalslide-form-group">
            <label>Message</label>
            <textarea
              className="modalslide-text"
              placeholder="Paste the article text here..."
              rows={5}
              onChange={onTextAreaChange}
              value={textValue}
            />

            {errorTextValue && (
              <div>
                <FaExclamation style={{ color: 'red', paddingRight: '5px', fontSize: '14px' }} />
                {errorTextValue}
              </div>
            )}
          </div>

          <div className="modalslide-form-buttons">
            <button type="submit" className="modalslide-submit">
              <span>Send</span>
              <span>
                <SendIcon size={22} strokeWidth={0} />
              </span>
            </button>
          </div>
        </form>
      </>
    </ModalSlide>
  );
}

export default Next15Policies;
