import { USER_STORAGE_KEY } from './auth';

export function clearAllButUser() {
  for (let index = 0; index < localStorage.length; index++) {
    const key = localStorage.key(index);
    if (key !== USER_STORAGE_KEY) {
      localStorage.removeItem(key);
    }
  }
}
