import { makeRequest } from '../makeRequest';
import type { ActionPayloadType, LlmAction, LlmInterface } from './types';
import { VectorStoreFile } from '../chats-remote';

// name will be presented in dropdown menu
export const NAME = 'OpenAI';

// it represents is LLM module available based on deployment config or any other rules
export function isAvailable() {
  return !!process.env.REACT_APP_BACKEND_URL;
}

export function request<PayloadType = ActionPayloadType>(resource: string, payload: PayloadType, options = {}) {
  return makeRequest(`${process.env.REACT_APP_BACKEND_URL}${resource}`, payload, options);
}

export function getRequest(resource: string) {
  return request(resource, {}, { body: null, method: 'GET' });
}

type VectorStoreResponseData = {
  assistantId: string;
  threadId: string;
  vectorStoreId: string;
  vectorStoreFiles: Array<string>;
};

export async function checkVectorStoreRequest(
  chatId: string,
  fileIds: Array<string> | null = null
): Promise<VectorStoreResponseData> {
  const response = await makeRequest(`${process.env.REACT_APP_BACKEND_URL}/check-vector-store/${chatId}`, {
    file_ids: fileIds
  });
  const {
    assistant_id: assistantId,
    thread_id: threadId,
    vector_store_id: vectorStoreId,
    vector_store_files: vectorStoreFiles
  } = await response.json();
  return { assistantId, threadId, vectorStoreId, vectorStoreFiles };
}

// define llm available actions and it's command connection
function generateImage(payload: ActionPayloadType, options = {}) {
  return request('/generate-image', payload, options);
}
generateImage.command = '/image';

function webSearch(payload: ActionPayloadType, options = {}) {
  return request('/web-search', payload, options);
}
webSearch.command = '/web-search';

function chat(payload: ActionPayloadType, options = {}) {
  return request('/chat', payload, options);
}

function fileAssistant(payload: ActionPayloadType, options = {}) {
  return request('/chat-file-assistant', payload, options);
}
fileAssistant.handlesRequest = {
  check: (params = { files: [] }) => {
    const { files } = params;
    return files?.length > 0;
  },
  buildActionParams: ({
    inputPrompt: prompt,
    files,
    chatId,
    ...payload
  }: { inputPrompt: string } & ActionPayloadType) => {
    const chatLog = payload?.chatLog || [];
    const sendChatLog = chatLog.map(({ chatPrompt, botMessage }) => ({
      user_message: chatPrompt,
      bot_response: botMessage
    }));
    return {
      chat_id: chatId,
      chat_log: sendChatLog,
      message: prompt,
      files: files.map(({ fileId: file_id, fileName: file_name }) => ({ file_id, file_name }))
    };
  },
  preUpdateChat: async ({ ...chatValues }) => {
    const chatId = chatValues.id as string;
    const { fileAssistant } = chatValues;
    const chatFilesIds = fileAssistant.vectorStoreFiles.map((file: VectorStoreFile) => file.fileId);
    const { vectorStoreId, threadId } = await checkVectorStoreRequest(chatId, chatFilesIds);
    return {
      ...chatValues,
      fileAssistant: {
        ...fileAssistant,
        threadId,
        vectorStoreId
      }
    };
  }
};

export const MHP_PROMPT = '/summarise-articles';
export const POLICIES_PROMPT = '/policies';
export const MHP_CHATLOG_DELIMETER = '\n';
export const MHP_ARTICLES_DELIMETER = '\n---\n';

type SummariseRequestParams = ActionPayloadType & {
  article_list: Array<{ url: string; text: string }>;
};

export function mhpArticleSummariseRequest({ ...payload }: SummariseRequestParams, options = {}) {
  return request('/mhp-article-summarise', { ...payload }, options);
}

function mhpArticleSummarise({ message, ...payload }: { message: string } & ActionPayloadType, options = {}) {
  console.log('{ message, ...payload }', { message, ...payload });
  const [...rows] = message.split('\n').slice(1);
  const messageText = rows.join('\n');
  const textList = messageText.split(MHP_ARTICLES_DELIMETER);
  const articleList = textList.map((text) => {
    const [url, ...rows] = text.split('\n');
    const messageText = rows.join('\n');
    return { url, text: messageText };
  });
  return mhpArticleSummariseRequest({ article_list: articleList, ...payload }, options);
}

mhpArticleSummarise.command = MHP_PROMPT;
mhpArticleSummarise.isModal = true;
mhpArticleSummarise.onlyBrands = ['Pulse', 'Next15'];
export const POLICIES_CHATLOG_DELIMETER = '\n';

function policies({ message, chatLog: history, ...payload }: { message: string } & ActionPayloadType, options = {}) {
  const [gptModel, ...text] = message.split('\n').slice(1);
  return request(
    '/n15-policies',
    {
      message: text.join('\n'),
      'gpt-model': gptModel,
      history,
      ...payload
    },
    options
  );
}
policies.command = POLICIES_PROMPT;
policies.isModal = true;
policies.onlyBrands = ['Next15'];

// LLM module must have exported actions and default key
export const actions = {
  generateImage,
  webSearch,
  mhpArticleSummarise,
  policies,
  fileAssistant,
  default: chat
};

class OpenAiLlm implements LlmInterface {
  get NAME() {
    return NAME;
  }
  isAvailable() {
    return isAvailable();
  }
  get actions() {
    return [generateImage, webSearch, mhpArticleSummarise, policies, fileAssistant, chat] as LlmAction[];
  }

  get defaultAction() {
    return chat as unknown as LlmAction;
  }
}

export const defaultLLM = new OpenAiLlm();
