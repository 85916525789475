import { RefObject } from 'react';

type OnCloseType = {
  (event: KeyboardEvent): void;
};

export function createEscapeHandler(onClose: OnCloseType) {
  function handleEscapeKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      onClose(event);
    }
  }
  document.addEventListener('keyup', handleEscapeKeyUp);
  return () => {
    document.removeEventListener('keyup', handleEscapeKeyUp);
  };
}

export default function createOutSideHandler(objRef: RefObject<any>, closeHandler: { (): void }) {
  const handleOutsideExpandable = (event: MouseEvent) => {
    if (objRef?.current) {
      if (!objRef.current.contains(event.target)) {
        closeHandler();
      }
    }
  };

  const handleEscapeKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        closeHandler();
        break;
      default:
        break;
    }
  };

  document.addEventListener('mousedown', handleOutsideExpandable);
  document.addEventListener('keyup', handleEscapeKeyUp);

  return () => {
    document.removeEventListener('mousedown', handleOutsideExpandable);
    document.removeEventListener('keyup', handleEscapeKeyUp);
  };
}
