import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { TbPrompt } from 'react-icons/tb';
import { RxCross2 } from 'react-icons/rx';

type ParamsType = {
  onToggleClick:
    | undefined
    | {
        (): void;
      };
  opened: boolean;
};

function PromptsMenuToggle({ onToggleClick = undefined, opened = false }: ParamsType) {
  const [isOpened, setIsOpened] = useState<boolean>(opened);

  const onClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      setIsOpened((currentOpened) => !currentOpened);
      if (onToggleClick) {
        onToggleClick();
      }
    },
    [onToggleClick]
  );

  useEffect(() => {
    if (!opened) {
      setIsOpened(() => false);
    }
  }, [opened]);

  return (
    <div className="sideMenuOpenWrapper">
      <button
        className={clsx({ sideMenuToggle: true, opened: isOpened })}
        type="button"
        onClick={onClick as unknown as React.MouseEventHandler<HTMLButtonElement>}
      >
        <div className="sideMenuToggleContentOpened">
          <RxCross2 size="22" />
        </div>
        <div className="sideMenuToggleContentClosed">
          <TbPrompt size="22" />
        </div>
      </button>
    </div>
  );
}

export default PromptsMenuToggle;
