import { User } from 'firebase/auth';

import { auth } from '../firebase.config';
import { prepareGoogleCredentials } from '../components/login/credentials';

export const USER_STORAGE_KEY = 'user';

export function getInitialUser() {
  try {
    return JSON.parse(localStorage.getItem(USER_STORAGE_KEY) as any);
  } catch (err) {
    return null;
  }
}

export function setUserData(userData: User) {
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userData));
}

export const OKTA_AUTH_ENABLED = process.env.REACT_APP_OKTA_AUTH_ENABLED === 'true';

export const AuthProviders = {
  OKTA: 'okta',
  FIREBASE: 'firebase'
};

export const getAuthProvider = () => {
  const user = getInitialUser();
  return user.provider ? user.provider : OKTA_AUTH_ENABLED ? AuthProviders.OKTA : AuthProviders.FIREBASE;
};

export const getAuthToken = () => {
  const user = getInitialUser();
  if (getAuthProvider() === AuthProviders.FIREBASE) {
    return user?.stsTokenManager?.accessToken || '';
  }
  try {
    const oktaTokenStorageString = localStorage.getItem('okta-token-storage');
    const oktaTokenStorage = oktaTokenStorageString ? JSON.parse(oktaTokenStorageString) : null;
    return oktaTokenStorage?.accessToken?.accessToken || '';
  } catch (e) {
    console.error('Failed to parse okta storage');
  }
  return '';
};

export const authHeaders = () => ({
  Authorization: getAuthToken(),
  'Auth-Provider': getAuthProvider()
});

export async function refreshFirebaseToken() {
  if (getAuthProvider() === AuthProviders.FIREBASE) {
    await auth.operations; // TODO: check if all fine
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
      setUserData(prepareGoogleCredentials(auth.currentUser) as User); // make sure new token in localstorage
    }
  }
}

export async function refreshOktaToken() {
  if (getAuthProvider() === AuthProviders.OKTA) {
    console.log('Refresh token');
    // TODO: placeholder to renew token
    // const token = await oktaAuth.tokenManager.get('accessToken');
  }
}

export async function refreshAuthToken() {
  await Promise.all([refreshFirebaseToken(), refreshOktaToken()]);
}
