import { DisplayUpload } from './display-uploads';

export type PendingReport = UploadStatusReport<'pending'> & {
  original_filename: string;
  vector_store_id: string;
};

export type UploadStatusReport<UploadStatus extends string> = {
  upload_status: UploadStatus;
};

export type RequiresAIReport = UploadStatusReport<'requires_ai'> & {
  // pages: Array<string>;
  total_pages: number;
};

export type ReadingReport = UploadStatusReport<'reading'> & {
  total_pages: number;
};

export type FinishedReadingPageContentReport = UploadStatusReport<'finished_content_page_reading'> & {
  content: string;
};

export type FinishedPageReadingReport = UploadStatusReport<'finished_page_reading'>;

export type FinishedReadingReport = UploadStatusReport<'finished_reading'>;

export type StartSavingReport = UploadStatusReport<'start_saving'> & {
  vector_store_file_id: string;
  file_content: string;
};

export type FinishedReport = UploadStatusReport<'finished'>;

export type FailedReport = UploadStatusReport<'failed'> & {
  message: string;
};

export type FileUploadReport =
  | PendingReport
  | RequiresAIReport
  | ReadingReport
  | FinishedReadingPageContentReport
  | FinishedReadingReport
  | StartSavingReport
  | FinishedReport
  | FailedReport
  | FinishedPageReadingReport;

export class ReportCollection {
  private _reports: Array<FileUploadReport>;

  constructor(initReport: FileUploadReport) {
    this._reports = [initReport];
  }

  add(report: FileUploadReport) {
    this._reports.push(report);
  }

  withReport(report: FileUploadReport) {
    this.add(report);
    return this;
  }

  findReport<ReportType>(uploadStatus: string): ReportType {
    const report = this._reports.find(({ upload_status }) => upload_status === uploadStatus);
    this._raiseIfNot(report, `report with status '${uploadStatus}'`);
    return report as ReportType;
  }

  calculateProgress(): [number, number] {
    let done = 0;
    let total = 0;

    for (const itemReport of this._reports) {
      if (itemReport.upload_status === 'pending') {
        continue;
      }
      if (itemReport.upload_status === 'requires_ai') {
        done = 1;
        total = 1 + itemReport.total_pages * 2 + 1;
      }
      if (itemReport.upload_status === 'reading') {
        done = 1;
        total = 1 + itemReport.total_pages * 2 + 1;
      }
      if (itemReport.upload_status === 'finished_reading') {
        // done *= 2;
      }
      if (itemReport.upload_status === 'finished') {
        done = total;
      }
      if (itemReport.upload_status === 'finished_page_reading') {
        done += 1;
      }
      if (itemReport.upload_status === 'finished_content_page_reading') {
        done += 1;
      }
    }

    return [done, total];
  }

  get displayedUpload(): DisplayUpload {
    return {
      isPending: true,
      originalFilename: this.originalFileName,
      progress: this.calculateProgress(),
      failed: this.isFailed,
      statusMessage: this._statusMessage()
    };
  }

  get requiresAI(): boolean {
    // return this._reports.length === 1 && this._reports[0].upload_status === 'requires_ai';
    return this._reports.some((report) => report.upload_status === 'requires_ai');
  }

  get isSaving(): boolean {
    return this._reports[this._reports.length - 1].upload_status === 'start_saving';
  }

  get isFailed(): boolean {
    return this._reports.some((report) => report.upload_status === 'failed');
  }

  get totalPages(): number {
    return this.findReport<RequiresAIReport>('requires_ai').total_pages;
  }

  get pagesContentList(): Array<string> {
    const pageFinishedReadingReports = this._reports.filter(
      (report) => report.upload_status === 'finished_content_page_reading'
    ) as Array<FinishedReadingPageContentReport>;
    return pageFinishedReadingReports.map((report) => report.content);
  }

  get originalFileName(): string {
    let pendingReport = this._reports.find(({ upload_status }) => upload_status === 'pending') as
      | PendingReport
      | undefined;
    this._raiseIfNot(pendingReport, 'pending report');
    pendingReport = pendingReport as PendingReport;
    return pendingReport.original_filename;
  }

  get vectorStoreFileId(): string {
    for (const report of this._reports) {
      // if (['finished', 'start_saving'].indexOf(report.upload_status)) {
      if (report.upload_status === 'start_saving') {
        return (report as StartSavingReport).vector_store_file_id;
      }
    }
    throw new Error('Cannot retrieve file id');
  }

  get fileContent(): string {
    const finishedReport = this.findReport<StartSavingReport>('start_saving');
    return finishedReport.file_content;
  }

  _raiseIfNot(value: any, valueName: string) {
    if (!value) {
      throw new Error(`Value of '${valueName}' is empty`);
    }
  }

  _statusMessage() {
    const lastReport = this._reports[this._reports.length - 1];
    switch (lastReport.upload_status) {
      case 'failed':
        return `${lastReport.message || 'Failed'}`;
      case 'finished':
        return 'Finished';
      case 'finished_content_page_reading':
        return 'Reading pages with AI';
      case 'pending':
        return 'Pending';
      case 'reading':
        return 'Extracting document content';
      case 'requires_ai':
        return 'Connecting AI to extract content';
      case 'start_saving':
        return 'Saving document';
      case 'finished_page_reading':
        return 'Reading pages';
      case 'finished_reading':
        return 'Prepare document for saving';
    }
  }
}
