const getEndStringRegexp = (suggestion: string) => {
  const [leadingSlash, ...keywordChars] = suggestion;
  keywordChars.reverse();
  const chars = keywordChars.reduce((result, char) => `(${char}${result})?`, `(${keywordChars.shift()}?)`);
  return new RegExp(`.*${leadingSlash}${chars}$`);
};

const suggestionMatchedByInput = (suggestion: string, input: string) => {
  const matchRegExp = getEndStringRegexp(suggestion);
  return matchRegExp.test(input);
};

export const filterSuggestionsMatchedByInput = (input: string, autocompleteSuggestions: Array<string>) => {
  const inputTrim = (input || '').trim();
  return inputTrim
    ? autocompleteSuggestions.filter((suggestion) => suggestionMatchedByInput(suggestion, inputTrim))
    : autocompleteSuggestions;
};
