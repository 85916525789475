import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ChatFileUploadContext, UploadedFile, UploadedFileState } from '../context/ChatFileUpload';
import { FaAngleUp as ActiveArrow, FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import { ChatType } from '../data/chats-type';
import createOutSideHandler from '../utils/hot-toggle';
import ProgressLoaderCircle from './ProgressLoaderCircle';
import { progressPercents } from '../utils/math';

type Params = {
  chat: ChatType | null | undefined;
};

type ChatFile = UploadedFileState;
type LegacyChatFile = UploadedFileState;

const showAsSelected = (file: ChatFile | LegacyChatFile) => {
  return !file.changingSelection ? file.selected : !file.selected;
};

function InputAreaUploadedFiles({ chat }: Params) {
  const uploadedFilesRef = useRef<HTMLDivElement>(null);
  const { uploadedFiles, filesInUploadReports, uploadingProgress, changeFileSelection } =
    useContext(ChatFileUploadContext);
  const [totalListItems, setTotalListItems] = useState<number>(0);
  const legacyContainerRef = useRef<HTMLDivElement | null>(null);
  const legacyMessageRef = useRef<HTMLDivElement | null>(null);
  const [uploadedFilesClosed, setUploadedFilesClosed] = useState(true);
  const [filesLabel, setFilesLabel] = useState('');
  const [selectedUploadedFileIds, setSelectedUploadedFileIds] = useState<Array<string>>([]);
  const [files, setFiles] = useState<Array<ChatFile>>([]);
  const [legacyFiles, setLegacyFiles] = useState<Array<LegacyChatFile>>([]);

  const onBarLabelOpenedClick = useCallback(() => {
    if (!uploadedFilesClosed) {
      setUploadedFilesClosed(() => true);
    }
  }, [uploadedFilesClosed]);

  const onUploadedFilesClose = useCallback(() => {
    setUploadedFilesClosed(() => true);
  }, []);

  const onUploadedFilesClick = useCallback(() => {
    if (uploadedFilesClosed) {
      setUploadedFilesClosed(() => false);
    }
  }, [uploadedFilesClosed]);

  const onSelectionChangeClick = useCallback(
    (file: ChatFile, e: MouseEvent) => {
      e.preventDefault();
      const changingSome = uploadedFiles.some(({ changingSelection }) => changingSelection);
      if (!changingSome) {
        changeFileSelection(chat as ChatType, file as UploadedFile)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      }
    },
    [changeFileSelection, chat, uploadedFiles]
  );

  const onLegacySelectionClick = useCallback(
    (legacyFile: LegacyChatFile, e: MouseEvent) => {
      e.preventDefault();
      const changingSome = uploadedFiles.some(({ changingSelection }) => changingSelection);
      if (!changingSome) {
        changeFileSelection(chat as ChatType, legacyFile as UploadedFile, true)
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      }
    },
    [changeFileSelection, chat, uploadedFiles]
  );

  useEffect(() => {
    if (totalListItems > 0) {
      if (uploadedFilesClosed) {
        if (filesInUploadReports.some(({ failed }) => !failed)) {
          setFilesLabel(() => 'Uploading');
        } else if (selectedUploadedFileIds.length > 0) {
          const fileNames = uploadedFiles
            .filter(({ fileId }) => selectedUploadedFileIds.includes(fileId))
            .map(({ fileName }) => fileName)
            .join(', ');

          setFilesLabel(() => fileNames);
        } else {
          setFilesLabel(() => `Files: (${uploadedFiles.length})`);
        }
      } else {
        setFilesLabel(() => '');
      }
    } else {
      setFilesLabel(() => '');
    }
  }, [
    uploadedFilesClosed,
    uploadedFiles,
    selectedUploadedFileIds,
    chat,
    totalListItems,
    filesInUploadReports,
    uploadingProgress
  ]);

  useEffect(() => {
    if (legacyContainerRef?.current && legacyMessageRef?.current) {
      const legacyContainer = legacyContainerRef.current;
      const legacyMessage = legacyMessageRef.current;
      const onMouseOver = () => {
        if (!legacyMessage?.classList.contains('displayed')) {
          legacyMessage?.classList.add('displayed');
          setTimeout(() => {
            legacyMessage?.classList.add('shown');
          }, 1);
        }
      };
      const onMouseLeave = () => {
        if (legacyMessage?.classList.contains('displayed')) {
          legacyMessage?.classList.remove('displayed');
          legacyMessage?.classList.remove('shown');
        }
      };
      const messageToCursor = (moveEvent: MouseEvent) => {
        legacyMessage?.animate(
          {
            left: `${String(moveEvent.clientX + 20)}px`,
            top: `${String(moveEvent.clientY + 10)}px`
          },
          {
            duration: 100,
            fill: 'forwards'
          }
        );
      };
      legacyContainer.addEventListener('mouseover', onMouseOver);
      legacyContainer.addEventListener('mouseout', onMouseLeave);
      legacyContainer.addEventListener('mousemove', messageToCursor);
      return () => {
        legacyContainer.removeEventListener('mouseover', onMouseOver);
        legacyContainer.removeEventListener('mouseout', onMouseLeave);
        legacyContainer.removeEventListener('mousemove', messageToCursor);
      };
    }
  }, [legacyContainerRef, legacyMessageRef]);

  useEffect(() => {
    setSelectedUploadedFileIds(
      () => chat?.fileAssistant?.vectorStoreFiles.filter(({ selected }) => selected).map(({ fileId }) => fileId) || []
    );
  }, [chat]);

  useEffect(() => {
    const enabledFiles: Array<ChatFile> = [];
    const legacyFiles: Array<LegacyChatFile> = [];
    for (const file of uploadedFiles) {
      if (file.isLegacy) {
        legacyFiles.push(file as LegacyChatFile);
      } else {
        enabledFiles.push(file as ChatFile);
      }
    }
    setFiles(() => enabledFiles);
    setLegacyFiles(() => legacyFiles);
  }, [uploadedFiles]);

  useEffect(() => {
    setTotalListItems(() => uploadedFiles.length + filesInUploadReports.length);
  }, [uploadedFiles, filesInUploadReports]);

  useEffect(() => {
    return createOutSideHandler(uploadedFilesRef, onUploadedFilesClose);
  }, [uploadedFilesRef, onUploadedFilesClose]);

  return (
    <div
      className={clsx(
        'chat-files',
        uploadedFiles.length + filesInUploadReports.length === 0 && 'hidden',
        uploadedFilesClosed && 'closed'
      )}
      ref={uploadedFilesRef}
      onClick={onUploadedFilesClick}
    >
      <div className="chat-files-list" style={{ height: Number(!uploadedFilesClosed) * (totalListItems * 40 + 20) }}>
        {files.map((file, fileIndex) => (
          <div
            className={clsx('uploaded-file', showAsSelected(file) && 'selected')}
            key={`chat-file-list-${file.fileId}`}
          >
            <div
              className={clsx('uploaded-file-check', file.changingSelection ? 'changing' : 'clickable')}
              onClick={(e) => onSelectionChangeClick(file, e as unknown as MouseEvent)}
            >
              <div className="checked">
                <FaCheckCircle size={15} />
              </div>
              <div className="unchecked">
                <FaRegCheckCircle size={15} />
              </div>
            </div>
            <div className="uploaded-file-name">{file.fileName}</div>
          </div>
        ))}
        {legacyFiles.map((legacyFile) => (
          <div className={clsx('uploaded-file')} key={`legacy-file-${legacyFile.fileId}`}>
            <div
              className={clsx('uploaded-file-check', legacyFile.changingSelection ? 'changing' : 'clickable')}
              onClick={(e) => onLegacySelectionClick(legacyFile, e as unknown as MouseEvent)}
            >
              <div className="checked">
                <FaCheckCircle size={15} />
              </div>
              <div className="unchecked">
                <FaRegCheckCircle size={15} />
              </div>
            </div>
            <div className="uploaded-file-name">{legacyFile.fileName}</div>
          </div>
        ))}
        <div className="file-uploads">
          {filesInUploadReports.map((uploadReport, index, all) => {
            if (!uploadReport.failed) {
              const [done, total] = uploadReport.progress;
              return (
                <div key={`report-${index}`} className="uploaded-file">
                  <div className="file-upload-progress">
                    <ProgressLoaderCircle progressPercentage={progressPercents(done, total)} />
                  </div>
                  <div className="uploading-file-info">
                    <div className="uploaded-file-name">{uploadReport.originalFilename}</div>
                    <div className="uploading-file-info__status">{uploadReport.statusMessage}</div>
                  </div>
                </div>
              );
            } else {
              return (
                <div key={`report-${index}`} className="uploaded-file">
                  <div className="file-upload-progress"></div>
                  <div className="uploading-file-info">
                    <div className="uploaded-file-name">{uploadReport.originalFilename}</div>
                    <div className="uploading-file-info__status">{uploadReport.statusMessage}</div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="chat-files-bar">
        {uploadedFilesClosed && uploadingProgress !== -1 && (
          <div className="loader-wrapper">
            <ProgressLoaderCircle progressPercentage={uploadingProgress} />
          </div>
        )}
        <div className="chat-files-bar-name" onClick={onBarLabelOpenedClick}>
          {filesLabel}
        </div>
        <div className="chat-files-bar-indicator">
          <ActiveArrow className="indicator" />
        </div>
      </div>
    </div>
  );
}

export default InputAreaUploadedFiles;
