import { createSlice } from '@reduxjs/toolkit';

export type Suggestions = Array<{
  suggestion: string;
}>;

type StateType = {
  canShowSuggestions: boolean;
  highlightedSuggestionIndex: number;
  filteredSuggestions: Array<string>;
  selectedSuggestion: string;
  selectedModalSuggestion: string;
};

const initialState: StateType = {
  canShowSuggestions: false,
  highlightedSuggestionIndex: -1,
  filteredSuggestions: [],
  selectedSuggestion: '',
  selectedModalSuggestion: ''
};

export const suggestionsStoreSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    closeAndResetSuggestions(state, { payload: filteredSuggestions }) {
      state.canShowSuggestions = false;
      state.highlightedSuggestionIndex = -1;
      state.selectedModalSuggestion = '';
      state.filteredSuggestions = filteredSuggestions;
    },
    enableSuggestions(state) {
      state.canShowSuggestions = true;
    },
    setFilteredSuggestions(state, { payload: filteredSuggestions }) {
      state.filteredSuggestions = filteredSuggestions;
    },
    setHighlightedSuggestion(state, { payload: selectedIndex }) {
      state.highlightedSuggestionIndex = selectedIndex;
    },
    setSelectedModalSuggestion(state, { payload: { suggestion, filteredSuggestions } }) {
      state.canShowSuggestions = false;
      state.selectedModalSuggestion = suggestion;
      state.filteredSuggestions = filteredSuggestions;
    }
  }
});

export const {
  closeAndResetSuggestions,
  enableSuggestions,
  setFilteredSuggestions,
  setHighlightedSuggestion,
  setSelectedModalSuggestion
} = suggestionsStoreSlice.actions;

export const canShowSuggestionsSelector = (state: { suggestions: StateType }) => state.suggestions.canShowSuggestions;

export const filteredSuggestionsSelector = (state: { suggestions: StateType }) => state.suggestions.filteredSuggestions;

export const highlightedSuggestionIndexSelector = (state: { suggestions: StateType }) =>
  state.suggestions.highlightedSuggestionIndex;

export const selectedModalSuggestionSelector = (state: { suggestions: StateType }) =>
  state.suggestions.selectedModalSuggestion;

export const showingSuggestionsSelector = (state: { suggestions: StateType }) =>
  state.suggestions.canShowSuggestions && state.suggestions.filteredSuggestions.length > 0;
