import React from 'react';
import { useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import useSearch from '../hooks/useSearch';

const SearchInput = () => {
  const { search } = useSearch();
  const [query, setQuery] = useState('');
  const formRef = useRef(null);

  const onSubmit = (event: Event) => {
    event.preventDefault();
    search(query);
    return false;
  };

  return (
    <form onSubmit={onSubmit as unknown as React.FormEventHandler<HTMLFormElement>} ref={formRef}>
      <div className="search-container">
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {query && (
          <AiOutlineClose
            className="clean-search"
            onClick={() => {
              setQuery('');
              search('');
            }}
          />
        )}
      </div>
    </form>
  );
};

export default SearchInput;
