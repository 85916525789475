import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signInWithPopup } from 'firebase/auth';

import { auth, microsoftProvider } from '../../firebase.config';
import { setUser } from '../../data/ui';
import { UserCredential, User } from 'firebase/auth';

const allowedDomains = (process.env.REACT_APP_LOGIN_DOMAIN as string).split(',');

type UserType = User & { email: string };

export default function MicrosoftSignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignInWithMicrosoft = async () => {
    try {
      let userCredential: UserCredential = await signInWithPopup(auth, microsoftProvider);
      const user: UserType = userCredential.user as UserType;
      if (!allowedDomains.some((suffix) => user.email.endsWith(suffix))) {
        throw new Error('Unexpected email address');
      }
      dispatch(setUser(user.toJSON()));
      // once user is signed in navigate them to the home page
      navigate('/');
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };

  return (
    <button id="signupWithMicrosoft" onClick={handleSignInWithMicrosoft}>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" width={20} height={20}>
          <path fill="#F65314" d="M12 12h32v32H12z" />
          <path fill="#7CBB00" d="M44 12h32v32H44z" />
          <path fill="#00A1F1" d="M12 44h32v32H12z" />
          <path fill="#FFBB00" d="M44 44h32v32H44z" />
        </svg>
      </i>
      Continue with Microsoft
    </button>
  );
}
