import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH_ENABLED } from './utils/auth';

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK === 'true';
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

export const oktaConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER as string,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    services: {
      autoRenew: true
    }
  }
};

export let oktaAuth: undefined | OktaAuth;

if (OKTA_AUTH_ENABLED) {
  oktaAuth = new OktaAuth(oktaConfig.oidc);
}
