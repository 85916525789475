import { configureStore } from '@reduxjs/toolkit';
import { chatsApi, syncChatsSlice } from './chats-remote';
import { chatsLegacyApi } from './chats';
import { uiSlice } from './ui';
import { requestSlice } from './makeRequest';
import { suggestionsStoreSlice } from './prompts/suggestions-slice';

export const store = configureStore({
  reducer: {
    [uiSlice.name]: uiSlice.reducer,
    [requestSlice.name]: requestSlice.reducer,
    [syncChatsSlice.name]: syncChatsSlice.reducer,
    [chatsApi.reducerPath]: chatsApi.reducer,
    [chatsLegacyApi.reducerPath]: chatsLegacyApi.reducer,
    [suggestionsStoreSlice.name]: suggestionsStoreSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(chatsApi.middleware).concat(chatsLegacyApi.middleware)
});
